import React from 'react'
import './contactus.css';
// import { FaLocationDot } from 'react-icons';
// import { FaLocationDot} from 'react-icons';
import { FaPhone } from "react-icons/fa6";
import { HiOutlineMail, HiLocationMarker } from "react-icons/hi";

const contactus = () => {
  return (
    <>
      <div id='contact'>
        <div className='contact_tital'>
          <h1>Get In Touch</h1>
          <h4>Looking forward to connect with you</h4>
        </div >
        <div className='contact_content' >
          <div className='map_' >
            <iframe className='contact_map' title='map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3504.906785777301!2d77.01249227549752!3d28.542521375714088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMyJzMzLjEiTiA3N8KwMDAnNTQuMiJF!5e0!3m2!1sen!2sin!4v1696006718691!5m2!1sen!2sin"
            ></iframe>
          </div>
          <div className='contact_data' >
            <div>
              <h5><FaPhone className='icon FaPhone' /> 9319881018</h5>
            </div>
            <div>
              <h5><HiOutlineMail className='icon HiOutlineMail' />info@puppytheworld.in</h5>
            </div>
            <div>
              <h5><HiLocationMarker className='icon HiLocationMarker' />Isapur Khera, Dwarka, Delhi, PIN-110077</h5>
            </div>
            <div>
              <h4>Opening Hour</h4>
              <h6>Sun 8Am--8Pm <span className='span_p'>|</span> Sat 8Am--8Pm</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default contactus