import React from 'react'
import './about.css'

const About = () => {
  return (
    <>
      <div className='about'>
        <h1>Deepak</h1>
        <div className='about_content' >
          <div className='dimg'>
            <img className='deepak_img' src='assets/img/deepak-about.jpeg' alt='img' />
          </div>
          <div>
            <p>Since 2019, we’ve been proudly supporting pet parents throughout the New <br /> Delhi area. Motivated by a passion for animals and deep understanding of quality pet care,<br /> we continue to serve a growing client base and expand our service offerings to <br /> meet their needs. When you and your furry friend come to Pet <a href='https://en.wikipedia.org/wiki/Dog_daycare'>Home Boarding</a>,<br /> you become part of our family.
              <br />🏆 Expertise and Dedication:
              <br/>Our journey began in 2019 when I decided to turn my love for dogs into<br /> a profession. Since then, we've successfully trained countless dogs, <br />helping them become better, more obedient members of your family. Our commitment<br /> to positive reinforcement techniques ensures your pet's comfort and happiness throughout<br /> the dog training process.<br />

              <br />📍 Located in new delhi:<br />
              You can find your best pet training near new delhi, where we've built a strong reputation for delivering <br />exceptional training services in the heart of pet training delhi take a <a href='https://wa.me/9319881018'>dog classes now</a>. Our dedication to canine<br /> well-being has made us a trusted choice among pet owners in the area.Get <a href='https://wa.me/9319881018'>dog training at home </a></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About