import React from 'react'
import './petgallery.css'

const petgallery = () => {
  return (
    <>
    <div className='gallerycont' >
        <h1>Gallery</h1>
        
        <div className='gallery' >
            <img src='./assets/img/deepak/pic1.jpeg' alt=''/>
            <img src='./assets/img/deepak/pic2.jpeg' alt=''/>
            <img src='./assets/img/deepak/pic3.jpeg' alt=''/>
            <img src='./assets/img/deepak/pic4.jpeg' alt=''/>
            <img src='./assets/img/deepak/pic5.jpeg' alt=''/>
            <img src='./assets/img/deepak/pic6.jpeg' alt=''/>
        </div>
    </div>
    </>
  )
}

export default petgallery