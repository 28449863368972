import React from 'react'
import './petboarding-section.css'

const petboarding = () => {
  return (
    <>
      <div>
        <div className='pett'>
          <div className='petboarding'>
          </div>
        </div>
      </div>
    </>
  )
}

export default petboarding