import React, { useState, useEffect } from 'react';
import './review.css'

const reviewsData = [
  {
    id: 1,
    author: 'John Doe',
    text: 'Great pet care! Our pet enjoys it. Friendly, caring staff. Clean place. We highly recommend for a happy and safe pet stay.',
    image: './assets/img/reviewdog/rdog1.jpg',
  },
  {
    id: 2,
    author: 'Jane',
    text: 'Outstanding service! Our pet feels at home. Clean and welcoming. Staff is wonderful. We choose it every time. Highly recommended.',
    image: './assets/img/reviewdog/rdog2.jpg',
  },
  {
    id: 3,
    author: 'Rahul',
    text: 'Best pet boarding ever! Our pets adore it here. Excellent facilities. Experienced and caring staff. We have full confidence in their care.',
    image: './assets/img/reviewdog/rdog3.jpg',
  },
  {
    id: 4,
    author: 'nisha',
    text: 'Our dog loves it. Courteous staff. Convenient location. We trust them with our furry family members. Highly recommended',
    image: './assets/img/reviewdog/rdog4.jpg',
  },
  {
    id: 5,
    author: 'sonali',
    text: 'Knowledgeable and caring staff. Safe and clean environment. We appreciate their dedication to our pets well-being',
    image: './assets/img/reviewdog/rdog5.jpg',
  },
];

const Review = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [currentReview, setCurrentReview] = useState(reviewsData[currentReviewIndex]);

  // Function to handle automatic review rotation
  const rotateReview = () => {
    const newIndex = (currentReviewIndex + 1) % reviewsData.length;
    setCurrentReviewIndex(newIndex);
    setCurrentReview(reviewsData[newIndex]);
  };

  useEffect(() => {
    // Automatically rotate reviews every 5 seconds (5000 milliseconds)
    const rotationInterval = setInterval(rotateReview, 5000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(rotationInterval);
    };
  }, [currentReviewIndex]);

  return (
    <div className="review-section">
      <div>
      <h1>Feedback From our Coustomer</h1>
        <p>Valuable Feedback from <span> Satisfied Customers:</span> <br/> Their Words Speak Volumes <br/> About Our <span>Service</span>.</p>
      </div>
      <div className="review">
      <img src={currentReview.image} alt={currentReview.author} />
        <p>{currentReview.text}</p>
        <p className='name'><span>-</span> {currentReview.author}</p>
      </div>
    </div>
  );
};

export default Review;
