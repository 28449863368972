import React from 'react'
import './product.css'

const product = () => {
  return (
    <>
    <div className='comming_soon'>
      <img src='assets/img/comming soon.jpg' alt='comming soon' />
      <h4>LAUNCHING OUR PRODUCT SOON . . .</h4>
    </div>
   </>
  )
}

export default product