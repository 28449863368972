import React from 'react'
import './boardingmain.css'

const boardingmain = () => {
    return (
        <>
            <div className='boardingmain'>
                <div className='petboarding_containt'>
                    <h2>What is Boarding & <br /> Boarding with <span>Training?</span></h2>
                    <p>Safe, comfortable pet boarding for your furry <br /> family members.<span>Book now!</span></p>
                    <a href='https://wa.me/9319881018'><button>Book a Seassion</button></a>
                </div>

                <div className='offer'>
                    <h2>What We Offer?</h2>
                    <h3>We help you relax knowing your dog will be treated like<br /> family when with us.</h3>
                    <div className='petb_content'>
                        <ul>
                            <li>Your pets enjoy cozy stays in our spacious boarding facility <span>24×7</span>.</li>
                            <li>Travel worry-free, knowing your pets are in safe, loving hands.</li>
                            <li>Our experienced staff ensures your pet's well-being, health, and happiness.</li>
                            <a href='https://wa.me/9319881018'><button>Book a Seassion</button></a>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default boardingmain