import React from 'react'
import { FaWhatsapp } from "react-icons/fa6";
import { BiSolidPhoneCall } from "react-icons/bi";
import './icon.css'

const icon = () => {
  return (
    <>
    <div className='whatsicon'>
    <a href="tel:9319881018"><BiSolidPhoneCall className='callicon'/></a>
        <a href='https://wa.me/9319881018'><FaWhatsapp className='whatsappicon'/></a>
    </div>
    </>
  )
}

export default icon