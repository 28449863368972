import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import './traininghero.css'

const traininghero = () => {
  return (
    <>
      <div className='bg_img'id='train-h'>
        <div>
        <div className='traininghero_content'>
          <h1>Start Your <span> Dog's Training </span> Journey Here <br /> for <span> Great Results!</span></h1>
          <h4>Your Neighborhood's Top Choice for Expert Canine Instruction.<br /> Discover Trusted Trainers Today.</h4>
        </div>
        <div className='hero_button' >
        <a href="tel:9319881018"><button>Book a Seasons</button></a>
        <Link className='trans-link' smooth to="#transf"><h4>Explore More <span>→</span></h4></Link>
        </div>
        </div>
      </div>
    </>
  )
}

export default traininghero